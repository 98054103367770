import React from 'react';
import { Title } from '../../../commons/components/Title';
import { Box, Flex } from '@chakra-ui/react';
import { InteractionT } from '../../model/Interaction.ts';
import isEmpty from 'lodash/isEmpty';
import { SlideArrows } from '../../../presentation/components/SlideArrows';
import { translate } from '../../../../translate';

interface Props {
	interaction: InteractionT;
	isSubmitting?: boolean;
	onSubmit: (e?: React.BaseSyntheticEvent<object, unknown, unknown>) => Promise<void>;
	children: React.ReactNode;
}

const HONEY_POT_FIELD_NAME = 'countryReference';

export const InteractionWrapper: React.FC<Props> = ({ interaction, isSubmitting, onSubmit, children }) => {
	const handleOnSubmit = (e?: React.BaseSyntheticEvent<object, unknown, unknown>) => {
		const honeyPotValue = document.getElementById(HONEY_POT_FIELD_NAME) as HTMLInputElement | null;
		if (isEmpty(honeyPotValue?.value)) {
			onSubmit(e);
		}
	};
	return (
		<>
			<Flex as='section' h='93%' overflowY='auto'>
				<Box as='form' id={interaction.id} m='auto' onSubmit={handleOnSubmit} w={{ base: '90%', sm: '90%', md: '90%', xl: '80%' }} padding={4}>
					<Title title={interaction.title} description={interaction.description} />
					<input id={HONEY_POT_FIELD_NAME} name={HONEY_POT_FIELD_NAME} type='text' style={{ display: 'none' }} />
					{children}
					{/*<Flex justifyContent='center' mt={0}>*/}
					{/*	<Button*/}
					{/*		rightIcon={!isSubmitSuccessful ? <MdOutlineArrowForwardIos /> : <IoMdCheckmark />}*/}
					{/*		isLoading={isSubmitting}*/}
					{/*		onSubmit={handleOnSubmit}*/}
					{/*		variant='submit'*/}
					{/*		type='submit'*/}
					{/*		mt={6}*/}
					{/*		isDisabled={isSubmitting}*/}
					{/*		_disabled={{*/}
					{/*			_hover: {*/}
					{/*				cursor: 'no-drop',*/}
					{/*				backgroundColor: 'brand',*/}
					{/*				opacity: 0.4,*/}
					{/*			},*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		{!isSubmitSuccessful ? translate('Submit') : translate('Submitted')}*/}
					{/*	</Button>*/}
					{/*</Flex>*/}
					<SlideArrows onSubmit={handleOnSubmit} canSkip={!interaction.required} isSubmitting={isSubmitting} submitText={translate('Submit')} />
				</Box>
			</Flex>
		</>
	);
};

InteractionWrapper.defaultProps = {
	isSubmitting: false,
};
