import { Alert as ChakraAlert, AlertIcon, AlertProps, AlertTitle, Center, Text } from '@chakra-ui/react';
import React from 'react';

interface Props extends AlertProps {
	title?: string;
}

export const AlertBox: React.FC<Props> = ({ title, status, ...props }) => {
	return (
		<Center>
			<ChakraAlert status={status} {...props} borderRadius={'3xl'}>
				<AlertIcon />
				<AlertTitle>
					<Text color='#2D3748'>{title}</Text>
				</AlertTitle>
			</ChakraAlert>
		</Center>
	);
};

AlertBox.defaultProps = {
	status: 'error',
};
