import { useQuery } from '@tanstack/react-query';
import { getWordCloudData } from '../service/VisualizationService.ts';
import { refetchInterval } from './visualization-commons-hook.ts';

const key = (presentationId: string, visualizationId: string) => ['presentation', presentationId, 'visualization', visualizationId, 'word-cloud'];

export const useWordCloudData = (presentationId: string, visualizationId: string) => {
	return useQuery({
		queryKey: key(presentationId, visualizationId),
		queryFn: () => getWordCloudData(presentationId, visualizationId),
		refetchInterval,
	});
};
