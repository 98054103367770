import { useQuery } from '@tanstack/react-query';
import { getBlocksData } from '../service/VisualizationService.ts';
import { refetchInterval } from './visualization-commons-hook.ts';

const key = (presentationId: string, visualizationId: string) => ['presentation', presentationId, 'visualization', visualizationId, 'blocks'];

export const useBlocksData = (presentationId: string, visualizationId: string) => {
	return useQuery({
		queryKey: key(presentationId, visualizationId),
		queryFn: () => getBlocksData(presentationId, visualizationId),
		refetchInterval,
	});
};
