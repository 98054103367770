import { useQuery } from '@tanstack/react-query';
import { getComicData } from '../service/VisualizationService.ts';
import { refetchInterval } from './visualization-commons-hook.ts';

const key = (presentationId: string, visualizationId: string) => ['presentation', presentationId, 'visualization', visualizationId, 'comic'];

export const useComicData = (presentationId: string, visualizationId: string) => {
	return useQuery({
		queryKey: key(presentationId, visualizationId),
		queryFn: () => getComicData(presentationId, visualizationId),
		refetchInterval,
	});
};
