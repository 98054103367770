import { useQuery } from '@tanstack/react-query';
import { getBarChartData } from '../service/VisualizationService.ts';
import { refetchInterval } from './visualization-commons-hook.ts';

const key = (presentationId: string, visualizationId: string) => ['presentation', presentationId, 'visualization', visualizationId, 'bar-chart'];

export const useBarChartData = (presentationId: string, visualizationId: string) => {
	return useQuery({
		queryKey: key(presentationId, visualizationId),
		queryFn: () => getBarChartData(presentationId, visualizationId),
		refetchInterval,
	});
};
