import { ElementT } from '../../presentation/model/Presentation.ts';

export interface InteractionT extends ElementT {
	configuration: InteractionConfigurationT;
	type: InteractionTypeT;
	required: boolean;
}

export enum InteractionTypeT {
	OPEN_ENDED = 'OPEN_ENDED',
	OPEN_ENDED_LONG = 'OPEN_ENDED_LONG',
	CATEGORISED = 'CATEGORISED',
	VALUATION = 'VALUATION',
	IMAGE = 'IMAGE',
	DRAWING = 'DRAWING',
	NUMBER = 'NUMBER',
	MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
	ICONIC = 'ICONIC',
	LINK = 'LINK',
}

export interface InteractionConfigurationT {
	id: number;
}

export interface OpenEndedInteractionConfigurationT extends InteractionConfigurationT {
	answersRequired: number;
	maximumAnswers: number;
	placeholder: string;
}

export interface CategorisedInteractionConfigurationT extends InteractionConfigurationT {
	maximumAnswers: number;
	categories: CategorisedConfigurationCategoryItem[];
}

export interface ValuationInteractionConfigurationT extends InteractionConfigurationT {
	minValue: number;
	maxValue: number;
	minValuePlaceholder: string;
	maxValuePlaceholder: string;
	statements: ValuationConfigurationStatementItem[];
}

export interface ValuationConfigurationStatementItem {
	id: number;
	statement: string;
	color: string;
}

export interface CategorisedConfigurationCategoryItem {
	id: number;
	category: string;
	color: string;
}

export interface NumberInteractionConfigurationT extends InteractionConfigurationT {
	placeholder: string;
	valueReference: string;
	minValue: number;
	maxValue: number;
}

export interface MultipleChoiceConfigurationT extends InteractionConfigurationT {
	allowOthers: boolean;
	maxOthers: number;
	multipleAnswer: boolean;
	options: string[];
}

export interface ImageInteractionConfigurationT extends InteractionConfigurationT {
	selectedColor: string;
	placeholderTitle: string;
	placeholderDescription: string;
	longAnswer: boolean;
}

export interface IconicInteractionConfigurationT extends InteractionConfigurationT {
	placeholderTitle: string;
	placeholderDescription: string;
	longAnswer: boolean;
}

export interface DrawingInteractionConfigurationT extends InteractionConfigurationT {
	placeholderTitle: string;
	placeholderDescription: string;
	longAnswer: boolean;
}

export interface LinkInteractionConfigurationT extends InteractionConfigurationT {
	placeholder: string;
	openInNewTab: boolean;
}
